// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary-rgb: 0, 255, 0;
  --ion-color-primary: rgb(var(--ion-color-primary-rgb));
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-contrast: rgb(var(--ion-color-primary-contrast-rgb));
  --ion-color-primary-shade: #00e000;
  --ion-color-primary-tint: #1aff1a;

  /** secondary **/
  --ion-color-secondary-rgb: 28, 61, 74;
  --ion-color-secondary: rgb(var(--ion-color-secondary-rgb));
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-contrast: rgb(var(--ion-color-secondary-contrast-rgb));
  --ion-color-secondary-shade: #193641;
  --ion-color-secondary-tint: #33505c;

  /** tertiary **/
  --ion-color-tertiary-rgb: 15, 38, 46;
  --ion-color-tertiary: rgb(var(--ion-color-tertiary-rgb));
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-contrast: rgb(var(--ion-color-tertiary-contrast-rgb));
  --ion-color-tertiary-shade: #0d2128;
  --ion-color-tertiary-tint: #273c43;

  /** success **/
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success: rgb(var(--ion-color-success-rgb));
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-contrast: rgb(var(--ion-color-success-contrast-rgb));
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning: rgb(var(--ion-color-warning-rgb));
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-contrast: rgb(var(--ion-color-warning-contrast-rgb));
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger: rgb(var(--ion-color-danger-rgb));
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-contrast: rgb(var(--ion-color-danger-contrast-rgb));
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark: rgb(var(--ion-color-dark-rgb));
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-contrast: rgb(var(--ion-color-dark-contrast-rgb));
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium: rgb(var(--on-color-medium-rgb));
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-contrast: rgb(var(--ion-color-medium-contrast-rgb));
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light-rgb: 15, 38, 46;
  --ion-color-light: rgb(var(--ion-color-light-rgb));
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-contrast: rgb(var(--ion-color-light-contrast-rgb));
  --ion-color-light-shade: #0d2128;
  --ion-color-light-tint: #273c43;
}

body {
  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: var(--color-petrol);
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: var(--color-petrol-variant-light);
  --ion-color-step-350: var(--color-petrol-variant-light);
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
}
