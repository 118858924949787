:root {
  --ion-font-family: "Poppins";
  --color-petrol: #062a30;
  --color-petrol-variant-light: #1c3d4a;
  --color-petrol-variant-dark: #0f262e;
  --color-neon-green: #00ff00;
  --color-neon-green-variant: #d5efbe;
  --color-white: #fff;
  --color-black: #000;
  --color-grey: #7b7b7b;
  --color-red: red;

  --ion-background-color: var(--color-petrol-variant-light);
  --ion-background-color-rgb: 28, 61, 74;

  --ion-alert-background: var(--color-petrol-variant-light);
  --ion-card-background: var(--color-petrol-variant-dark);
  --ion-item-background: var(--color-petrol-variant-light);
  --ion-toolbar-background: var(--color-petrol-variant-dark);
  --ion-searchbar-background: var(--color-petrol-variant-light);

  --ion-card-title-color: var(--color-white);

  --ion-text-color: var(--color-white);
  --ion-text-color-rgb: 255, 255, 255;

  --ion-border-color: var(--color-petrol-variant-dark);
}

ion-toolbar {
  padding: 0.5rem !important;
}

ion-list-header {
  color: var(--color-white);
}

ion-modal {
  --ion-background-color: var(--color-petrol-variant-dark);
  --ion-toolbar-background: var(--color-petrol-variant-dark);
  --ion-toolbar-border-color: var(--color-petrol-variant-dark);
}

ion-card {
  border-radius: 18px !important;
  --color: var(--color-white);
}

ion-card-title {
  color: var(--color-white);
  font-size: 1.4rem;
  font-weight: 500;
}

ion-searchbar {
  --icon-color: var(--color-white) !important;
  --clear-button-color: var(--color-white);
  --cancel-button-color: var(--color-white);
  --background: var(--color-petrol-variant-dark);
  background-color: var(--color-petrol-variant-light);
}

ion-content {
  height: 100%;
  --background: var(--color-petrol-variant-light);
}

ion-back-button {
  color: var(--color-white);
}

.sc-ion-alert-ios-h {
  --background: var(--color-petrol-variant-light);
}

.user-info-popover,
.popover-content {
  --width: 240px;
}

.event-popover,
.popover-content {
  --width: 195px;
}

.mapbox-logo {
  display: none;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapbox-improve-map {
  display: none;
}

.mapboxgl-ctrl-compass {
  display: none;
}
